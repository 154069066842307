import * as React from "react";
import { useState } from "react";
import "../i18n";
import "./style.css";
import Layout from "../components/Layout/Layout";
import { useTranslation } from "react-i18next";
import * as styles from "./pricing-plans.module.css";
import PricingPanel from "../components/PricingPanel/PricingPanel";
import FeaturesTable from "../components/FeaturesTable/FeaturesTable";
import FAQ from "../components/FAQ/FAQ";
import Seo from "../components/shared/Seo";

export default function PricingPlans() {
  const { t } = useTranslation();
  let [period, setPeriod] = useState("year");
  return (
    <Layout>
      <Seo title={"Pricing Plans"} description={"Find the plan to suit your needs and take back control of the recruitment process."} canonicalPath={"/pricing-plans"}/>
      <div className={styles.plans_pricing}>
        <h1 className={styles.mainTitle}>{t("pricing_plans.main_title")}</h1>
        <p className={styles.subTitle}>{t("pricing_plans.sub_title")}</p>
        <div className={styles.switchBtn}>
          <h2 className={`${styles.annually} ${period === "year" && styles.selectedPlan}`} onClick={
            () => setPeriod("year")
          }>{t("annually")}</h2>
          <h2 className={`${styles.monthly} ${period === "month" && styles.selectedPlan}`} onClick={
            () => setPeriod("month")
          }>{t("monthly")}</h2>
        </div>
        <div className={styles.pricing_panels}>
          {Object.entries(t("pricing_plans.plans")).map(([key, plan], index) => <PricingPanel key={index} id={key}
                                                                                              title={plan.title}
                                                                                              price={period === "year" ? plan.annually_price : plan.monthly_price}
                                                                                              per={period === "year" ? t("pricing_plans.plans.essential.per_year") : t("pricing_plans.plans.essential.per_month")}
                                                                                              user={plan.users}
                                                                                              jobs={plan.jobs}
                                                                                              text1={plan.text1}
                                                                                              remark={plan.remark}
                                                                                              button={plan.button}
                                                                                              perks={plan.perks}
                                                                                              advanced={plan.advanced}/>)}
        </div>
      </div>
      <div className={styles.features}>
        <h1 className={styles.features_title}>{t("pricing_plans.features.title")}</h1>
        <div className={styles.features_list}>
          {Array.from(t("pricing_plans.features.list")).map((feature, index) => <FeaturesTable key={index}
                                                                                          content={feature} />)}
        </div>
      </div>
      <div className={styles.faq}>
        <h1 className={styles.faq_title}>{t("pricing_plans.faqs.title")}</h1>
        <div className={styles.faq_list}>
          {Array.from(t("pricing_plans.faqs.list")).map((item, index) => <FAQ key={index} question={item.question}
                                                                              answer={item.answer} />)}
        </div>
      </div>
    </Layout>
  );
}

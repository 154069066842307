// extracted by mini-css-extract-plugin
export var advanced_panel = "pricing-panel-module--advanced_panel--RqnwQ";
export var container = "pricing-panel-module--container--93z+M";
export var item = "pricing-panel-module--item--Z8EKS";
export var jobs = "pricing-panel-module--jobs--VVCNL";
export var line = "pricing-panel-module--line--j43k-";
export var perks = "pricing-panel-module--perks--3rx0E";
export var price = "pricing-panel-module--price--L5viz";
export var priceRecommendedTag = "pricing-panel-module--price-recommended-tag--ML9YS";
export var price_value = "pricing-panel-module--price_value--YBsL1";
export var remark = "pricing-panel-module--remark--WRVOJ";
export var start_button = "pricing-panel-module--start_button--4xOid";
export var text = "pricing-panel-module--text--CQhxq";
export var text1 = "pricing-panel-module--text1--AIdqW";
export var text2 = "pricing-panel-module--text2--RsheO";
export var title = "pricing-panel-module--title--MD82y";
export var users = "pricing-panel-module--users--0PZF9";
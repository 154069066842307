// extracted by mini-css-extract-plugin
export var annually = "pricing-plans-module--annually--4Mjdx";
export var container = "pricing-plans-module--container--25nJZ";
export var faq = "pricing-plans-module--faq--aJgPA";
export var faq_list = "pricing-plans-module--faq_list--SyQVh";
export var faq_title = "pricing-plans-module--faq_title--1l9UB";
export var features = "pricing-plans-module--features--wqKcv";
export var features_list = "pricing-plans-module--features_list--7GXvr";
export var features_title = "pricing-plans-module--features_title--VZMwh";
export var mainTitle = "pricing-plans-module--main-title--fTEK2";
export var monthly = "pricing-plans-module--monthly--23U-z";
export var plans_pricing = "pricing-plans-module--plans_pricing--otX72";
export var pricing_panels = "pricing-plans-module--pricing_panels--LX6Yn";
export var selectedPlan = "pricing-plans-module--selected-plan--Ko15b";
export var subTitle = "pricing-plans-module--sub-title---OhJ-";
export var switchBtn = "pricing-plans-module--switchBtn--XsBF7";
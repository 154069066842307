import React from "react";
import * as styles from "./features-table.module.css";
import check from "../../../static/icons/check.svg";
import dash from "../../../static/icons/dash.svg";
import { useTranslation } from "react-i18next";

export default function FeaturesTable({ content }) {
  const { t } = useTranslation();
  return (
    <table className={styles.container} data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease" data-sal-duration="700">
      <thead>
      <th className={styles.table_title}>{content.title}</th>
      <th className={styles.column_title}>{t("pricing_plans.plans.essential.title")}</th>
      <th className={styles.column_title}>{t("pricing_plans.plans.advanced.title")}</th>
      </thead>
      <tbody>
      {content.elements.map((element, index) => {
        if (element?.isNumericFeature) {
          return (<tr key={index}>
            <td className={styles.row_title}>{element.title}</td>
            <td className={styles.numeric_feature}>{element.Essential}</td>
            <td className={styles.numeric_feature}>{element.Advanced}</td>
          </tr>);
        }
        return (<tr key={index}>
          <td className={styles.row_title}>{element.title}</td>
          <td>{element.Essential ? (<img src={check} alt="YES" />) : (<img src={dash} alt="NO" />)}</td>
          <td>{element.Advanced ? (<img src={check} alt="YES" />) : (<img src={dash} alt="NO" />)}</td>
        </tr>);
      })}
      </tbody>
    </table>
  );
}

import React from "react";

import * as styles from "./pricing-panel.module.css";
import person from "../../../static/icons/person.svg";
import job from "../../../static/icons/job.svg";
import StartButton from "../Start-Button/Start-button";
import check from "../../../static/icons/check.svg";
import {useTranslation} from "react-i18next";


export default function PricingPanel({
                                         title, price, per, user, jobs, text1, remark, button, perks, newTab= false, actionButton, buttonColor, advanced = false
                                     }) {
    const {t, i18n} = useTranslation();
    return (
        <div data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease" data-sal-duration="700" className={`${styles.container} ${advanced && styles.advanced_panel}`}>
            <div className={styles.title}>
                <h1>{title}</h1>
                {advanced && <p className={styles.priceRecommendedTag}>
                    {t('pricing_plans.recommended_tag')}
                </p>}
            </div>
            <div className={styles.price}>
                <div className={styles.price_value}>$ {price}</div>
                <div className={styles.text}>{per}</div>
            </div>
            <div className={styles.line}>
                <div className={styles.users}>
                    <img src={person}/>
                    <p>{user}</p>
                </div>
                <div className={styles.jobs}>
                    <img src={job}/>
                    <p>{jobs}</p>
                </div>
            </div>
            <p className={styles.text1}>{text1}</p>
            <div className={styles.start_button}>
                <StartButton text={button} chevron={false} filled={advanced}
                             to={`${actionButton ? actionButton : `${process.env.GATSBY_BASE_URL}/register?lang=${i18n.language}`}`}
                             newTab={newTab}
                             filedColor={buttonColor}
                />
            </div>
            <p className={styles.remark}>{remark}</p>
            {perks && <div className={styles.perks}>
                <h6 className={styles.title}>{perks.title}</h6>
                <ul>
                    {perks.list.map((item, index) => (<li key={index}>
                        <img src={check}/>
                        <p className={styles.item}>{item}</p>
                    </li>))}
                </ul>
            </div>}
        </div>
    );
}
